import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, retryWhen, delay, take } from 'rxjs/operators';
import { User } from '@app/profile';
import { Response } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpService: HttpClient) { }

  /**
   * Get Logged in User
   */
  getUser() {
    return this.httpService.get('api/v1/user').pipe(
      map((res: Response) => res.data as User),
      retryWhen(errors => errors.pipe(delay(2000), take(60)))
    );
  }
}
