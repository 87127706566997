import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Renderer2 } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, untilDestroyed, BroadcastTabService } from '@app/core';
import { ConnectionService } from '@app/offline';
import { PopupCountService } from './shared/popup';

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  /**
   * Overlay
   */
  @ViewChild('overlay', { static: true })
  overlayRef: ElementRef;

  /**
   * Main
   */
  @ViewChild('main', { static: true })
  mainRef: ElementRef;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private popupCountService: PopupCountService,
    private connectionService: ConnectionService,
    private broadcastTabService: BroadcastTabService,
    private renderer2: Renderer2) { }

  ngOnInit() {

    // toggle blur on popup show/hide
    this.popupCountService.count$.subscribe(v => this.toggleBlur(v));

    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });

    // connection online service
    this.connectionService.start();

    // watch for channel tabs messages
    this.broadcastTabService.watch();
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  /**
   * Toggles blur effect on popup show
   */
  private toggleBlur(v: number): void {
    if (v > 0) {
      this.renderer2.addClass(this.overlayRef.nativeElement, 'blurry');
    } else {
      this.renderer2.removeClass(this.overlayRef.nativeElement, 'blurry');
    }
  }

}
