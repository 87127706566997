import { AfterViewInit, Renderer2, ElementRef, HostBinding, HostListener, Directive } from '@angular/core';
import { Observable, Subject } from 'rxjs';

/**
 * Popup Interface
 */
export declare interface Popup {

  saving?: boolean;

  /**
   * Subject
   */
  closeSubject: Subject<string>;

  /**
   * Event
   */
  closeEvent: Observable<string>;

  /**
   * Passed Data
   */
  data: any;

  /**
   * Set Data
   */
  setData?(data: any): void;
}

/**
 * Popup Absctract Class
 */
@Directive()
export abstract class PopupBase implements Popup, AfterViewInit {

  blockEsc = false;

  /**
   * Saving flag
   */
  saving?: boolean;

  /**
   * Data
   */
  data: any;

  /**
   * Subject
   */
  closeSubject = new Subject<string>();

  /**
   * on KeyUpEvent
   */
  private onKeyUpEvent: any;

  constructor(public renderer2: Renderer2,
              public elementRef: ElementRef) {}

  /**
   * Event
   */
  get closeEvent(): Observable<string> {
    return this.closeSubject.asObservable();
  }

  /**
   * Animation host binding
   */
  @HostBinding('@openClose') get() {}

  /**
   * Listen to escape keypress - close if not saving
   */
  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler() {
    if (this.saving === true) { return; }
    if (this.blockEsc === true) { return; }
    this.closeSubject.next('key.escape');
  }

  ngAfterViewInit() {
    // get main element
    const mainElement = this.elementRef.nativeElement.children[0];

    // create wrap element
    const wrapElement = this.renderer2.createElement('div');
    // add classes
    this.renderer2.addClass(wrapElement, 'popup-overlay');
    this.renderer2.addClass(wrapElement, 'inner');
    this.renderer2.addClass(wrapElement, 'bg-white');
    this.renderer2.addClass(wrapElement, 'rounded');
    this.renderer2.addClass(wrapElement, 'relative');

    // wrap
    this.renderer2.removeChild(this.elementRef.nativeElement, this.elementRef.nativeElement.children[0]);
    this.renderer2.appendChild(wrapElement, mainElement);
    this.renderer2.appendChild(this.elementRef.nativeElement, wrapElement);
  }

  /**
   * Set Passed from parent data
   */
  setData(data: any): void {
    this.data = data;
  }
}

