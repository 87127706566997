import { Injectable } from '@angular/core';
import { BehaviorSubject, of, Subject } from 'rxjs';

@Injectable()
export class OfferService {

  offerSubject$ = new BehaviorSubject<any>(null);

  constructor() { }

  upadteOfferNumber(offer: any) {
    this.offerSubject$.next(offer);
  }

  get offerNumber() {
    return this.offerSubject$.asObservable();
  }

}
