<!-- Swiper -->
<div class="swiper swiper-container mySwiper bg-white pt-6 pb-md-6 px-6 border-t border-light">
  <div class="swiper-wrapper">
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo pzu" src="assets/single/pzu.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo nationale" src="assets/single/nationale.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo link4" src="assets/single/link4.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo ergo hestia" src="assets/single/ergo.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo generali" src="assets/single/generali.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo aegon" src="assets/single/aegon.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo allianz" src="assets/single/allianz.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo compensa" src="assets/single/compensa.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="benefia benefia" src="assets/single/benefia.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo defend" src="assets/single/defend.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo proama" src="assets/single/proama.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo warta" src="assets/single/warta.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo uniqa" src="assets/single/uniqa.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo mtu" src="assets/single/mtv.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo wiener" src="assets/single/wiener.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo interrisk" src="assets/single/interrisk.svg" />
    </div>
    <div class="swiper-slide">
      <img class="logo mb-md-0 mb-6" alt="logo hdi" src="assets/single/hdi.svg" />
    </div>
  </div>
  <div class="swiper-pagination"></div>
</div>
