import { Component, OnDestroy, OnInit } from '@angular/core';

import { CredentialsService, I18nService, UserService } from '@app/core';
import { HeaderService } from '@app/services/header.service';
import { BurgerService } from './burger/service/burger.service';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { OfferService } from './offer-service/offer.service';
import { CalculationInfo } from '@app/calculator/model/calculation.model';
import { config } from '@env/config';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [BurgerService]
})
export class HeaderComponent implements OnInit, OnDestroy {

  logoUrl: string = config.logo.standard;

  offerNumber$: Observable<CalculationInfo>;

  constructor(private credentialsService: CredentialsService,
              private headerService: HeaderService,
              private burgerService: BurgerService,
              private i18nService: I18nService,
              private offerService: OfferService) { }

  ngOnInit() {
    this.burgerService.init();
    this.offerNumber$ = this.offerService.offerNumber;
  }

  ngOnDestroy() {
    this.burgerService.destroy();
  }

  get menuShow() {
    return this.burgerService.menuShow;
  }

  setLanguage(language: string) {
    this.i18nService.language = language;
  }

  get isCalculatorPage() {
    return this.headerService.getPage().includes('calculator') ;
  }

  get currentLanguage(): string {
    return this.i18nService.language;
  }

  get languages(): string[] {
    return this.i18nService.supportedLanguages;
  }

  get username(): string | null {
    return this.credentialsService.getUsername();
  }

  get isAuthenticated(): boolean {
      if (this.credentialsService.isAuthenticated() && this.credentialsService.isRulesAccepted()) {
          return true;
      } else {
          return false;
      }
  }

  show() {
    this.burgerService.menuShow.next(true);
  }

  hide() {
    this.burgerService.menuShow.next(false);
  }

  logoClick(): void {
    // nothing for now
  }

  calculatorClick(): void {
    // nothing for now
  }

  get externalUrl() {
    return environment.wpUrl;
  }


}
