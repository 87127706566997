import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService, BroadcastTabService } from '@app/core';
import { tap, catchError, mergeMap, finalize } from 'rxjs/operators';
import { throwError, Subscription, of } from 'rxjs';
import { CalculationsCurrentService } from '@app/calculations';
import { MessageService } from '@app/shared/message';
import { PopupService } from '@app/shared/popup/service/popup.service';
import { ActivatedAccountPopupComponent } from './activated-account-popup/activated-account-popup.component';

@Component({
  selector: 'app-activate',
  templateUrl: './activate.component.html',
  styleUrls: ['./activate.component.scss'],
  providers: [PopupService]
})
export class ActivateComponent implements OnInit, OnDestroy {

  /**
   * Subscription
   */
  private subscriptions = new Subscription();

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private calculationsCurrentService: CalculationsCurrentService,
    private messageService: MessageService,
    private broadcastTabService: BroadcastTabService,
    private popupService: PopupService
  ) { }

  ngOnInit() {
    // reset current calculation
    this.calculationsCurrentService.reset();

    // logout
    this.subscriptions.add(this.authenticationService.logout().pipe(
      mergeMap(() => this.authenticationService.identifyGuest().pipe(
        mergeMap(() => {

          const mail = this.activatedRoute.snapshot.params.mail;
          const hash = this.activatedRoute.snapshot.params.hash;

          return this.authenticationService.activate(mail, hash).pipe(
            tap(() => {
                this.popupService.open(ActivatedAccountPopupComponent);
            //   this.messageService.add('Konto zostało aktywowane', 'success');
            //   this.router.navigate(['/login']);
            }),
            catchError((error) => {
              this.messageService.add('Konto już aktywne', 'danger');
              this.router.navigate(['/login']);
              return throwError(error);
            }),
            finalize(() => {
              this.broadcastTabService.send('logged-out');
            })
          );
        })
      ))
    ).subscribe());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
