import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CalculationsCurrentService } from '../../../service/current/current.service';
import { CalculationInfo } from '@app/offers/model/offer.model';
import { Enter } from '@app/core/animation';
import { PopupService } from '@app/shared/popup';

import { DeletePopupComponent } from '../../../shared/popup/delete/delete.component';

@Component({
  selector: 'app-calculation-box',
  templateUrl: './box.component.html',
  animations: [Enter],
  providers: [PopupService],
  styleUrls: ['./box.component.scss']
})
export class CalculationBoxComponent implements OnInit {

  /**
   * Calculation
   */
  @Input()
  calculation: CalculationInfo;

  constructor(private router: Router,
              private popupService: PopupService,
              private calculationsCurrentService: CalculationsCurrentService) { }

  ngOnInit() {
  }

  /**
   * Is Calculation Done
   */
  get isDone() {
    return this.calculation.status === 'DONE';
  }

  /**
   * Set Calcualtion
   */
  setCalculationClick() {
    this.calculationsCurrentService.reset();
    this.calculationsCurrentService.calculationId = this.calculation.refId;
    this.router.navigate(['/', 'oferty']);
  }

  /**
   * Edit Click
   */
  editClick() {
    this.calculationsCurrentService.reset();
    this.calculationsCurrentService.calculationId = this.calculation.refId;
    this.router.navigate([`/`]);
  }

  /**
   * Open Delete Popup Click
   */
  deletePopupClick() {
    this.popupService.open(DeletePopupComponent, {calculatinId: this.calculation.refId});
    // this.calculationsCurrentService.reset();
    // this.calculationsCurrentService.calculationId = this.calculation.refId;
    // this.router.navigate([`/calculator`]);
  }

  // deleteCalculationClick() {
  //   this.calculationsService.delete(this.calculation.refId);
  // }

}
