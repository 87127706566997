import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Credentials, GuestCredentials } from '../model/credentials';
import * as uuid from 'uuid';

const jwtHelperService = new JwtHelperService();

const credentialsKey = 'credentials';
const guestCredentialsKey = 'credentialsGuest';

@Injectable({
  providedIn: 'root'
})
export class CredentialsService {

  /**
   * Credentials
   */
  private _credentials: Credentials = null;

  /**
   * GuestCredentials
   */
  private _guestCredentials: GuestCredentials = null;

  constructor() {
    // restore User credentials
    const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    if (savedCredentials) {
      this._credentials = JSON.parse(savedCredentials);
    }

    // restore Guest credentials
    const savedGuestCredentials = localStorage.getItem(guestCredentialsKey);
    if (savedGuestCredentials) {
      this._guestCredentials = JSON.parse(savedGuestCredentials);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this._credentials;
  }

  isRulesAccepted(): boolean {
      return this._credentials.rulesAndPolitics;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;

    if (credentials) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(credentialsKey, JSON.stringify(credentials));
    } else {
      sessionStorage.removeItem(credentialsKey);
      localStorage.removeItem(credentialsKey);
    }
  }

  /**
   * Sets the guest credentials.
   * @param guestCredentials The guest credentials.
   */
  setGuestCredentials(guestCredentials?: GuestCredentials) {
    this._guestCredentials = guestCredentials || null;

    if (guestCredentials) {
      localStorage.setItem(guestCredentialsKey, JSON.stringify(guestCredentials));
    } else {
      localStorage.removeItem(guestCredentialsKey);
    }
  }

  /**
   * Get Token
   */
  get token(): string {
    if (this._credentials) {
      return this._credentials.token;
    }
    return '';
  }

  /**
   * Get Refresh Token
   */
  get refreshToken(): string {
    if (this._credentials) {
      return this._credentials.refresh_token;
    }
    return '';
  }

  /**
   * Get Guest Token
   */
  get guestToken(): string {
    if (this._guestCredentials && this._guestCredentials.token) {
      return this._guestCredentials.token;
    }
    return '';
  }

  get rulesAndPolitics() {
      if (this._credentials) {
          return this._credentials.rulesAndPolitics
      }
      return null;
  }

  /**
   * Get Guest UUID - creates it if not created already
   */
  get guestUUID(): string {
    if (!this._guestCredentials || this._guestCredentials.uuid === null) {
      this.setGuestCredentials({
        uuid: uuid.v4(),
        token: null
      });
    }
    return this._guestCredentials.uuid;
  }

  /**
   * Check if Guest Ready
   */
  isGuestReady(): boolean {
    return this._guestCredentials && this._guestCredentials.token !== null;
  }

  /**
   * Set Tokens
   */
  setTokens(credentials: Credentials) {
    const remember = localStorage.getItem(credentialsKey) ? true : false;
    this.setCredentials(credentials, remember);
  }

  /**
   * Set Tokens
   */
  setGuestTokens(guestToken: string, guestUuid?: string) {
    this.setGuestCredentials({
      uuid: guestUuid ? guestUuid : this._guestCredentials.uuid,
      token: guestToken
    });
  }

  /**
   * Remove Guest Token
   */
  clearGuestTokens() {
    this.setGuestCredentials();
  }

  clearToken() {
      const sessionCredentials = sessionStorage.getItem('credentials');
      if (sessionCredentials) {
          sessionStorage.removeItem('credentials');
      } else {
          localStorage.removeItem('credentials');
      }
  }

  /**
   * Get Username based on Token
   */
  getUsername() {
    return jwtHelperService.decodeToken(this.token).username;
  }

  /**
   * Get User Email base on Token
   */
  getEmail() {
    return jwtHelperService.decodeToken(this.token).email;
  }

}
