import { Component, OnInit } from '@angular/core';

import { BurgerService } from './service/burger.service';

@Component({
    selector: 'app-burger',
    templateUrl: './burger.component.html',
    styleUrls: ['./burger.component.scss']
})
export class BurgerComponent implements OnInit {

  constructor(private burgerService: BurgerService) {
  }

  ngOnInit() {
  }

  get menuShow() {
    return this.burgerService.menuShow;
  }

  menuShowOn() {
    this.burgerService.menuShow.next(!this.burgerService.menuShow.value);
  }
}
