import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable()
export class WindowService {

  windowWidth: number = window.innerWidth;

  constructor() { }

  checkWindowWidth(width: number, event?: any) {
    if (event) {
      this.windowWidth = event.currentTarget.innerWidth;
      return (this.windowWidth >= width) ? true : false;
    } else {
      return (this.windowWidth >= width) ? true : false;
    }
  }

}
