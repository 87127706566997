import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Subscription, Subject } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { CalculationsCurrentService } from '../current/current.service';
import { Router } from '@angular/router';
import { StorageService } from '@app/core';

@Injectable()
export class CalculationsEditService {

  /**
   * Is Loading
   */
  loading = false;

  /**
   * Subscriptions
   */
  private subscriptions = new Subscription();

  /**
   * Update Subject
   */
  private updateSubject: Subject<boolean> = new Subject();

  constructor(private httpService: HttpService,
              private router: Router,
              private storageService: StorageService,
              private calculationsCurrentService: CalculationsCurrentService) { }

  init() {}

  destroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Get update
   */
  get update() {
    return this.updateSubject.asObservable();
  }

  /**
   * Edit Calculation
   * @param string Calculation Id
   */
  edit(calculationId: string) {

    // reset
    this.calculationsCurrentService.reset();

    if (this.loading) { return; }

    // show loader
    this.loading = true;
    this.updateSubject.next(true);

    // subscription
    this.subscriptions.add(this.httpService.clone(calculationId).pipe(
      tap((data) => {
        this.calculationsCurrentService.calculationId = data.calculation.refId;
      }),
      finalize(() => {
        // write flag to skip refresh message
        this.storageService.write('skip-refresh-message', true);
        this.router.navigate(['/']);
      })
    ).subscribe());
  }
}
