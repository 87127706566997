import { Injectable, ComponentRef, ApplicationRef, Injector, Renderer2, RendererFactory2, ComponentFactoryResolver } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Subscription, Subject, Observable } from 'rxjs';
import { PopupCountService } from './count.service';
import { filter } from 'rxjs/operators';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

@Injectable()
export class PopupService {

  /**
   * Simple Popup
   */
  popupComponentRef: ComponentRef<any>;

  /**
   * Closing Subject
   */
  private closingSubject = new Subject<string>();

  /**
   * New instance of Rendered2
   */
  private renderer2: Renderer2;

  /**
   * Subscription
   */
  private subscriptionClose: Subscription;

  /**
   * Subscription
   */
  private subscriptionRouter: Subscription;

  /**
   * Is Open Flag
   */
  private isOpen = false;

  constructor(private applicationRef: ApplicationRef,
              private injector: Injector,
              private rendererFactory2: RendererFactory2,
              private componentFactoryResolver: ComponentFactoryResolver,
              private router: Router,
              private popupCountService: PopupCountService) {
                this.renderer2 = rendererFactory2.createRenderer(null, null);
              }

  /**
   * Opens popup with component, attaches it to ApplicationRef and renders at the end of <body>
   */
  open(compnent: any, data?: any): void {
    if (this.isOpen) { return; }

    this.isOpen = true;
    // add open popup
    this.popupCountService.add();
    // resolve and create component
    this.popupComponentRef = this.componentFactoryResolver.resolveComponentFactory(compnent).create(this.injector);
    // set data
    if (data) {
      this.popupComponentRef.instance.setData(data);
    }
    // attache to applicationRef
    this.applicationRef.attachView(this.popupComponentRef.hostView);
    // render at the end of body
    this.renderer2.appendChild(document.querySelector('body'), this.popupComponentRef.location.nativeElement);
    // enable scroll
    disableBodyScroll(this.popupComponentRef.instance);
    // subscribe to close
    this.subscriptionClose = this.popupComponentRef.instance.closeEvent.subscribe((reason: string) => this.close(reason));
    // subscribe to route change
    this.subscriptionRouter = this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe(_ => this.close('navigation-start'));
  }

  /**
   * Close Event
   */
  get closeEvent(): Observable<string> {
    return this.closingSubject.asObservable();
  }

  /**
   * Close - is fired always when popup closes
   */
  close(reason: string): void {
    if (!this.isOpen) { return; }
    this.isOpen = false;
    // unsubscribe
    this.subscriptionClose.unsubscribe();
    this.subscriptionRouter.unsubscribe();
    // enable scroll
    enableBodyScroll(this.popupComponentRef.instance);
    // destroy component ref
    this.popupComponentRef.destroy();
    // subtract popup count
    this.popupCountService.remove();
    // pass close reason
    this.closingSubject.next(reason);
  }
}
