import { Injectable, ElementRef, NgZone } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScrollService {

  constructor(private ngZone: NgZone) {}

  scrollTo(position: number) {
    this.ngZone.runOutsideAngular(_ => {
      window.scrollTo({
        top: Math.floor(position + window.scrollY - 136),
        behavior: 'smooth'
      });
    });
  }
}
