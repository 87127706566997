import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PaginationModule } from '@app/shared/pagination';
import { LoaderModule } from '@app/shared';
import { InlineSVGModule } from 'ng-inline-svg';
import { DeletePopupModule } from './shared/popup/delete/delete.module';

import { CalculationsListComponent } from './shared/list/list.component';
import { CalculationBoxComponent } from './shared/list/box/box.component';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    PaginationModule,
    LoaderModule,
    InlineSVGModule,
    DeletePopupModule
  ],
  declarations: [
    CalculationsListComponent,
    CalculationBoxComponent
  ],
  exports: [
    CalculationsListComponent
  ]
})
export class CalculationsModule { }
