import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CurrencyPipe } from './currency/currency.pipe';
import { BBCodePipe } from './bbcode/bbcode.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    CurrencyPipe,
    BBCodePipe
  ],
  exports: [
    CurrencyPipe,
    BBCodePipe
  ]
})
export class PipeModule { }
