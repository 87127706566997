import { Component, OnInit} from '@angular/core';
import { CredentialsService } from '@app/core';
import { config } from '@env/config';

import { environment } from '@env/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  logoUrl: string = config.logo.reversed;

  constructor() { }

  ngOnInit() {
  }

  get externalUrl() {
    return environment.wpUrl;
  }

  get mpUrl() {
    return environment.mpUrl;
  }

  get buildDate() {
    return environment.buildDate;
  }

}
