import { Component, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popup-wrapper',
  templateUrl: './popup-wrapper.component.html',
  styleUrls: ['./popup-wrapper.component.scss']
})
export class PopupWrapperComponent {

    @Input() content: TemplateRef<any>;
    @Input() iconName: string;
    @Input() iconClass = 'icon icon-xxxl icon-secondary';
    @Input() title: string;
    @Input() titleSize = 'text-20';

    @Output() closeClicked = new EventEmitter<boolean>();

    public closeClick() {
        this.closeClicked.emit(true);
    }
}
