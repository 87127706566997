import { Component, OnInit, HostBinding } from '@angular/core';
import { OpenClose } from '../animation/overlay.animation';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
  animations: [OpenClose],
})
export class OverlayComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  @HostBinding('@openClose') get() {}

}
