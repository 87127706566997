import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';

import { MessagesComponent } from './messages.component';
import { MessageComponent } from './message/message.component';

@NgModule({
  imports: [
    CommonModule,
    InlineSVGModule
  ],
  declarations: [
    MessagesComponent,
    MessageComponent
  ],
  exports: [
    MessagesComponent
  ]
})
export class MessagesModule { }
