import { ConfigModel } from '@app/shared/models/config.model';

export const config: ConfigModel = {
    compareOffersPopupLoadingTimeout: 30, // seconds
    ngbTooltip: {
      container: 'body',
      placement: ['top', 'left']
    },
    logo: {
        standard: 'assets/single/pasaz_logo.png',
        reversed: 'assets/single/pasaz_logo_white.png',
        simple: 'assets/single/pasaz_logo.png',
        simpleReversed: 'assets/single/pasaz_logo_white.png'
    }
};
