import { Injectable } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { BroadcastService } from '../broadcast/broadcast.service';
import { filter, tap, map } from 'rxjs/operators';

import { HttpService } from '../http/http.service';
import { CredentialsService } from '../../authentication/credentials.service';

import { User } from '@app/profile';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  /**
   * User
   */
  user: User = null;

  /**
   * Email
   */
  email: string = null;

  /**
   * Subscriptions
   */
  subscriptions = new Subscription();

  constructor(private broadcastService: BroadcastService,
              private credentialsService: CredentialsService,
              private httpService: HttpService) { }

  init() {
    // subscribe to authentication, reload user on success
    this.subscriptions.add(this.broadcastService.broadcasts.pipe(filter(data => data.message === 'authenticated')).subscribe(_ => this.reloadUser()));
    // subscribe to authentication, reload user on success
    this.subscriptions.add(this.broadcastService.broadcasts.pipe(filter(data => data.message === 'logout')).subscribe(_ => this.reset()));
    // user profile saved
    this.subscriptions.add(this.broadcastService.broadcasts.pipe(filter(data => data.message === 'profile-saved')).subscribe(_ => this.reloadUser(true)));
    // load if user is already authenticated
    if (this.credentialsService.isAuthenticated() && this.credentialsService.isRulesAccepted()) {
      this.reloadUser();
    }
  }

  destroy() {
    this.subscriptions.unsubscribe();
    this.user = null;
    this.email = null;
  }

  /**
   * Reload User
   * @return true if loaded, false if not
   */
  reloadUser$(overwrite?: boolean): Observable<boolean> {
    // in case user already loaded
    if (this.user !== null && overwrite !== true) { return of(true); }

    // in case user not loaded - load
    return this.httpService.getUser().pipe(
      tap(user => {
        this.user = user;
        this.user.personal.email = this.credentialsService.getEmail();
      }),
      map(_ => true)
    );
  }

  /**
   * User Loaded
   */
  get loaded() {
    return this.user !== null;
  }

  /**
   * Reload User
   */
  private reloadUser(overwrite?: boolean) {
    this.subscriptions.add(this.reloadUser$(overwrite).subscribe());
  }

  /**
   * Reset data
   */
  private reset() {
    this.user = null;
    this.email = null;
  }

}
