import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserService } from '@app/core';

import { CalculationsCurrentService } from '@app/calculations';
@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  providers: [CalculationsCurrentService]
})
export class ShellComponent implements OnInit, OnDestroy {

  constructor(private userService: UserService,
              private calculationsCurrentService: CalculationsCurrentService) { }

  ngOnInit() {
    this.userService.init();
    this.calculationsCurrentService.init();
  }

  ngOnDestroy() {
    this.calculationsCurrentService.destroy();
    this.userService.destroy();
  }

}
