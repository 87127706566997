import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChoiceComponent } from './forms/choice/choice.component';
import { ChoiceOptionComponent } from './forms/choice/choice-option/choice-option.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { TooltipComponent } from './tooltip/tooltip.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { PopupWrapperComponent } from './popup/popup-wrapper/popup-wrapper.component';

const MODULES = [
  FormsModule,
  ReactiveFormsModule,
  NgbModule,
  InlineSVGModule,
];

const COMPONENTS = [
  ChoiceComponent,
  ChoiceOptionComponent,
  TooltipComponent,
  PopupWrapperComponent
]

@NgModule({
  imports: [
    CommonModule,
    ...MODULES
  ],
  declarations: [
    COMPONENTS,
  ],
  exports: [
    ...MODULES,
    ...COMPONENTS
  ]
})
export class SharedModule { }
