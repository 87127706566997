import { Injectable, Injector } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { CredentialsService } from '../authentication/credentials.service';
import { StorageService } from '../service/storage/storage.service';
import { Router } from '@angular/router';
import { PopupService } from '@app/shared/popup/service/popup.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  inflightAuthRequest: null | Observable<any>;

  private authenticationService: AuthenticationService;
  private credentialsService: CredentialsService;
  private storageService: StorageService;

  /**
   * Skip url with prefix
   */
  private skipUrlPrefix: Array<string> = [document.location.origin + '/assets/'];

  constructor(private router: Router,
              private injector: Injector) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {

    this.authenticationService = this.injector.get(AuthenticationService);
    this.credentialsService = this.injector.get(CredentialsService);
    this.storageService = this.injector.get(StorageService);

    // skip url with prefix
    const skipThisUrl = this.skipUrlPrefix.findIndex((url: string) => request.url.startsWith(url)) !== -1;
    if (skipThisUrl) { return next.handle(request); }

    // add token headers
    const authReq = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ` + (this.credentialsService.isAuthenticated() ? this.credentialsService.token : this.credentialsService.guestToken)
      }
    });

    // return httpRequest #FIXME - make as function, add guest support
    return next.handle(authReq).pipe(
      catchError((error: HttpErrorResponse) => {
        // return throwError(error);
        // for 401 errors
        if (error.status === 401) {
          if (this.credentialsService.isAuthenticated()) {
            return this.handleUserToken(next, authReq);
          } else {
            return this.handleGuestToken(error);
          }
        } else if (error.status === 401) {
            return this.handleUserToken(next, authReq);
        } else {
            return throwError(error);
        }
      }),
    );
  }

  /**
   * Handle User Token Refresh process
   */
  private handleUserToken(next: HttpHandler, authReq: HttpRequest<any>) {

    // check if inflight Auth Refresh Request exists
    if (!this.inflightAuthRequest) {
      this.inflightAuthRequest = this.authenticationService.refresh();
    }

    // handle inflight request
    return this.inflightAuthRequest.pipe(
      switchMap(() => {
        this.inflightAuthRequest = null;
        const authReqRepeat = authReq.clone({
          setHeaders: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ` + this.credentialsService.token
          }
        });
        return next.handle(authReqRepeat);
      }),
      catchError((errorRefreshToken: HttpErrorResponse) => {
        this.inflightAuthRequest = null;
        this.authenticationService.logout();
        return throwError(errorRefreshToken);
      })
    );
  }

  /**
   * Handle Guest Token - reload whole browser on error
   */
  private handleGuestToken(error: HttpErrorResponse) {
    this.router.navigate(['/', 'reset'], {skipLocationChange: true});
    return throwError(error);
  }
}
