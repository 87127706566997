import { Injectable, ApplicationRef, Injector, RendererFactory2, ComponentFactoryResolver, ComponentRef, Renderer2 } from '@angular/core';
import { merge, fromEvent, Observable, Observer } from 'rxjs';
import { map } from 'rxjs/operators';
import { PopupCountService } from '@app/shared/popup';
import { OverlayComponent } from '@app/offline/overlay/overlay.component';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';

@Injectable({
  providedIn: 'root'
})
export class ConnectionService {

  /**
   * Is Overlay Open
   */
  isOpen = false;

  /**
   * Error Counter
   */
  errorCounter = 0;

  /**
   * Simple Popup
   */
  private overlayComponentRef: ComponentRef<any>;

  /**
   * New instance of Rendered2
   */
  private renderer2: Renderer2;

  constructor(private applicationRef: ApplicationRef,
              private injector: Injector,
              private rendererFactory2: RendererFactory2,
              private componentFactoryResolver: ComponentFactoryResolver) {
                this.renderer2 = rendererFactory2.createRenderer(null, null);
              }

  start(): void {
    if (navigator.onLine) {
      this.close();
    } else {
      this.open();
    }
    this.watch().subscribe();
  }

  /**
   * Watch if online/offline
   */
  watch() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => {
        this.open();
        return false;
      })),
      fromEvent(window, 'online').pipe(map(() => {
        this.close();
        return true;
      })),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }

  /**
   * Add Error
   */
  add() {
    if (!navigator.onLine) {
      this.errorCounter++;
    }
  }

  /**
   * Reset
   */
  reset() {
    this.errorCounter = 0;
  }

  /**
   * Opens popup with component, attaches it to ApplicationRef and renders at the end of <body>
   */
  open(): void {
    if (this.isOpen) { return; }
    this.isOpen = true;
    // resolve and create component
    this.overlayComponentRef = this.componentFactoryResolver.resolveComponentFactory(OverlayComponent).create(this.injector);
    // attache to applicationRef
    this.applicationRef.attachView(this.overlayComponentRef.hostView);
    // render at the end of body
    this.renderer2.appendChild(document.querySelector('body'), this.overlayComponentRef.location.nativeElement);
    // enable scroll
    disableBodyScroll(this.overlayComponentRef.instance);
  }

  /**
   * Close - is fired always when popup closes
   */
  close(): void {
    if (!this.isOpen) { return; }
    this.isOpen = false;
    // reload page
    if (this.errorCounter > 0) {
      location.reload();
    }
    // enable scroll
    enableBodyScroll(this.overlayComponentRef.instance);
    // destroy component ref
    this.overlayComponentRef.destroy();
  }


}
