import { env } from './.env';

export const environment = {
  production: true,
  sw: false,
  version: env.npm_package_version,
  buildDate: env.build_date,
  serverUrl: 'https://api.polisy24.pl/',
  merkuryUrl: 'https://mercure.polisy24.pl/.well-known/mercure',
  wpUrl: 'https://pasazubezpieczeniowy.online/',
  mpUrl: 'https://multipolisa.pl/',
  defaultLanguage: 'pl-PL',
  supportedLanguages: ['pl-PL'],
  integrations: {
    facebookLoginId: '3450309675078292',
    googleLoginId: '215780792784-goibddgf2422bfkkudc92t8j830m8pu6.apps.googleusercontent.com',
  },
};
