import { AfterViewInit, Directive, ElementRef, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Subscription } from 'rxjs';
import { interval } from 'rxjs';
import { tap, map, distinctUntilChanged } from 'rxjs/operators';

@Directive({
  selector: '[appScrollable]'
})
export class ScrollableDirective implements OnInit, OnDestroy, AfterViewInit {

  scrollYRef: any;
  scrollYLineRef: any;

  interval = interval(50);

  private subscriptions = new Subscription();

  constructor(private elementRef: ElementRef,
              private renderer2: Renderer2,
              private ngZone: NgZone) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.setupVerticalScroll();
    this.runInterval();
  }

  get contetnElement() {
    return this.elementRef.nativeElement.firstElementChild;
  }

  setupVerticalScroll() {
    // get main element
    const mainElement = this.elementRef.nativeElement;
    // add scrollable class
    this.renderer2.addClass(mainElement, 'scrollable-y');
    // create scroll
    this.scrollYRef = this.renderer2.createElement('div');
    this.scrollYLineRef = this.renderer2.createElement('div');
    // add classes to created elemetns
    this.renderer2.addClass(this.scrollYRef, 'd-none');
    this.renderer2.addClass(this.scrollYRef, 'scroll');
    this.renderer2.addClass(this.scrollYRef, 'scroll-vertical');
    // wrap
    this.renderer2.appendChild(this.scrollYRef, this.scrollYLineRef);
    this.renderer2.appendChild(mainElement, this.scrollYRef);
  }

  /**
   * Checks every XX miliseconds if client height was changed, fire toggleVertivalScroll() on change.
   */
  private runInterval() {
    this.subscriptions.add(this.interval.pipe(
      map(() => this.contetnElement.clientHeight < this.contetnElement.scrollHeight),
      distinctUntilChanged(),
      tap((enable) => {
        this.toggleVertivalScroll(enable);
      }),
    ).subscribe());
  }

  /**
   * Enabel/Diesable Vertical Scroll
   */
  private toggleVertivalScroll(enable: boolean) {
    if (enable) {
      this.renderer2.removeClass(this.scrollYRef, 'd-none');
      this.ngZone.runOutsideAngular(() => {
        this.contetnElement.addEventListener('scroll', this.scrolling, false);
      });
    } else {
      this.renderer2.addClass(this.scrollYRef, 'd-none');
      this.ngZone.runOutsideAngular(() => {
        this.contetnElement.removeEventListener('scroll', this.scrolling, false);
      });
    }
  }

  private scrolling = (): void => {
    const percentTop = Math.floor((this.contetnElement.scrollTop / (this.contetnElement.scrollHeight - this.contetnElement.clientHeight)) * 1000) / 10;
    const pixelsTop = Math.floor((this.contetnElement.clientHeight - this.scrollYLineRef.clientHeight) * percentTop / 100);
    this.renderer2.setStyle(this.scrollYLineRef, 'top', pixelsTop + 'px');
  }

}
