import { trigger, style, transition, animate, query } from '@angular/animations';

export const Enter = trigger('Enter', [
  transition(':enter', [
    style({
      opacity: 0,
    }),
    animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({
      opacity: 1,
    })),
  ]),
  // transition(':leave', [
  //   style({
  //     opacity: 1,
  //   }),
  //   animate('5.4s cubic-bezier(0.5, 0, 0.1, 1)', style({
  //     opacity: 0,
  //   })),
  // ]),
]);

export const EnterList = trigger('EnterList', [
  transition('* <=> *', [
    query(':enter', [
      style({
        opacity: 0,
      }),
      animate('0.4s cubic-bezier(0.5, 0, 0.1, 1)', style({
        opacity: 1,
      })),
    ], {optional: true}),
  ]),
]);
