import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap, delay } from 'rxjs/operators';

import { BroadcastChannel } from 'broadcast-channel';

import { CredentialsService } from '@app/core/authentication/credentials.service';

@Injectable({
  providedIn: 'root'
})
export class BroadcastTabService {

  /**
   * Reload Subject
   */
  reload$ = new Subject();

  private broadcastChannel: BroadcastChannel<string> = new BroadcastChannel('main');
  private onMessage = new Subject<string>();

  constructor(private cradentailsService: CredentialsService) {
    this.broadcastChannel = new BroadcastChannel('main');
    this.broadcastChannel.onmessage = (message) => this.onMessage.next(message);
  }

  watch() {
    this.onMessage.subscribe((message) => {
      switch (message) {
        case 'logged-out':
          this.reload$.next(true);
          break;
        case 'logged-in':
          this.reload$.next(false);
          break;
      }
    });

    // reload
    this.reload$.pipe(
      delay(200),
      tap((clear) => {
        if (clear) {
          this.cradentailsService.setCredentials();
        }
        location.reload();
      })
    ).subscribe();
  }

  send(message: string): void {
    this.broadcastChannel.postMessage(message);
  }
}
