import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bbcode'
})
export class BBCodePipe implements PipeTransform {
  transform(value: string): string {
    // `[b], [u], [i]`
    let html = value;

    // underline
    html = html.split('[u]').join('<span class="text-underline">');
    html = html.split('[/u]').join('</span>');
    // bold
    html = html.split('[b]').join('<span class="font-bold">');
    html = html.split('[/b]').join('</span>');
    // italic
    html = html.split('[i]').join('<span class="font-italic">');
    html = html.split('[/i]').join('</span>');
    // break line
    html = html.split('[br]').join('</br>');

    // links
    const regex = new RegExp(/\[url=(.*?)\/url\]/g);
    const foundMatches = html.match(regex);

    // foreach found link - replace with html href
    if (foundMatches && foundMatches.length > 0) {
      foundMatches.forEach((foundMatch) => {
        const parts = foundMatch
          .split(/\[url=|\]|\[\/url/)
          .filter(v => v !== '');
        html = html
          .split(foundMatch)
          .join(`<a class="text-underline" href="${parts[0]}" target="_blank">${parts[1]}</a>`);
      });
    }

    return html;
  }
}
