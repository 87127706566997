import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class BurgerService {

  menuShow = new BehaviorSubject(false);

  constructor(private ngZone: NgZone) { }

  init() {
    this.watchOnScroll();
    this.watchOnResize();
  }

  destroy() {
    this.watchOnScroll();
    this.watchOnResize();
  }

  watchOnResize() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', () => {
        this.ngZone.run(() => {
          if (window.innerWidth >= 1200 && this.menuShow.value === true) {
            this.menuShow.next(false);
          }
        });
      }, false);
    });
  }

  /**
   * Watch for Blur
   */
  private watchOnScroll(): void {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('scroll', this.scroll, false);
    });
  }

  /**
   * Outside Box Click
   */
  private scroll = (): void => {
    this.ngZone.run(() => {
      if (this.menuShow.value === true) {
        this.menuShow.next(false);
      }
    });
  }
}
