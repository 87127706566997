import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Input, Output, EventEmitter, HostListener } from "@angular/core";
import { Subscription } from "rxjs";

import { CalculationsService } from "../../service/calculations/calculations.service";
import { Pagination } from "@app/shared/pagination";
import { Enter, EnterList } from "@app/core/animation";
import { WindowService } from "@app/shared/services/window.service";

@Component({
    selector: "app-calculations-list",
    templateUrl: "./list.component.html",
    styleUrls: ["./list.component.scss"],
    animations: [Enter, EnterList],
    providers: [CalculationsService],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalculationsListComponent implements OnInit, OnDestroy {
    @Output() onNewCalculationClicked = new EventEmitter<boolean>();

    public isDesktop: boolean;
    /**
     * Subscriptions
     */
    private subscriptions = new Subscription();

    constructor(private changeDetectorRef: ChangeDetectorRef, private calculationsService: CalculationsService, private windowService: WindowService) {}

    ngOnInit() {
        this.isDesktop = this.windowService.checkWindowWidth(991);
        this.subscriptions.add(this.calculationsService.updates.subscribe((_) => this.changeDetectorRef.detectChanges()));
        this.calculationsService.init();
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
        this.calculationsService.destroy();
    }

    newCalculationClicked() {
        this.onNewCalculationClicked.emit(true);
    }

    @HostListener("window:resize", ["$event"])
    onResize() {
        this.isDesktop = this.windowService.checkWindowWidth(991, event);
    }
    /**
     * Calculation
     */
    get calculations() {
        return this.calculationsService.calculations;
    }

    /**
     * Loaded flag
     */
    get loaded() {
        return this.calculationsService.loaded;
    }

    /**
     * Has Calculation
     */
    hasCalculations() {
        return this.calculations && this.calculations.length > 0;
    }

    /**
     * Pagination
     */
    setPagination(pagination: Pagination) {
        this.calculationsService.pagination = pagination;
    }

    /**
     * Total Items
     */
    get total(): number {
        return this.calculationsService.total;
    }

    /**
     * Pagination Limit
     */
    get limit(): number {
        return this.calculationsService.pagination.limit;
    }
}
