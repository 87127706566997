import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { ShellComponent } from './shell.component';
import { HeaderComponent } from './header/header.component';
import { BurgerComponent } from './header/burger/burger.component';
import { CompaniesComponent } from './companies/companies.component';
import { FooterComponent } from './footer/footer.component';
import { OfferService } from './header/offer-service/offer.service';

@NgModule({
  providers: [OfferService],
  imports: [
    CommonModule,
    TranslateModule,
    NgbModule,
    RouterModule
  ],
  declarations: [
    ShellComponent,
    HeaderComponent,
    BurgerComponent,
    CompaniesComponent,
    FooterComponent
  ]
})
export class ShellModule {
}
