import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';

import { UserService } from '../service/user/user.service';
import { catchError } from 'rxjs/operators';
import { CredentialsService } from './credentials.service';

@Injectable({
  providedIn: 'root'
})
export class UserGuard implements CanActivate {

  constructor(private router: Router,
              private credentialsService: CredentialsService,
              private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

    if (this.credentialsService.isAuthenticated() && this.credentialsService.isRulesAccepted()) {
      // JIT User Loading
      return this.userService.reloadUser$().pipe(catchError((err, caught) => {
        this.router.navigate(['/']);
        throw err;
      }));
    }

    return of(true);
  }
}
