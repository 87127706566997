import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { RxwebValidators, ReactiveFormConfig, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterModalComponent implements OnInit {

  registerForm: FormGroup;
  isLoading = false;

  constructor(
    private formBuilder: RxFormBuilder,
    public activeModal: NgbActiveModal,
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      email: ['', [RxwebValidators.required(), RxwebValidators.email()]],
      password: ['', [
        RxwebValidators.required(),
        RxwebValidators.password({validation:
          { minLength: 6, digit: true, specialCharacter: true, lowerCase: true, upperCase: true}
        })]],
      confirm_password: ['', [
        RxwebValidators.required(),
        RxwebValidators.password({validation:
          { minLength: 6, digit: true, specialCharacter: true, lowerCase: true, upperCase: true}
        }),
        RxwebValidators.compare({fieldName: 'password' })]],
      policy: [false, [RxwebValidators.requiredTrue()]]
    });
  }

  openLoginModal() {
    this.activeModal.dismiss();
  }

  handleSubmit() {
    if (this.registerForm.invalid) {
      this.registerForm.markAllAsTouched();
    } else {
      this.register();
    }
  }

  register() {}

  get email() {
    return this.registerForm.get('email');
  }

  get password() {
    return this.registerForm.get('password');
  }

  get confirm_password() {
    return this.registerForm.get('confirm_password');
  }

  get policy() {
    return this.registerForm.get('policy');
  }

}
