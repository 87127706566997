<div class="relative rounded box-popup shadow-popup">
    <div class="box-popup-content">

        <div class="mb-7">
            <div class="d-flex flex-column flex-md-row align-items-center justify-content-center">
                <ng-container *ngIf="iconName?.length">
                  <div [ngClass]="iconClass" class="mr-0 mr-md-2 mb-2 mb-md-0 icon-mp-blue" inlineSVG="assets/icons.svg#{{ iconName }}"></div>
                </ng-container>

                <ng-container *ngIf="title?.length">
                  <div title [ngClass]="titleSize" class="text-primary">{{ title }}</div>
                </ng-container>

            </div>
        </div>

        <div class="modal-content">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
    </div>

    <div class="popup-close-btn" (click)="closeClick()"></div>
</div>
