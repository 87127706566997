import { Component, OnInit, Input, ContentChildren, AfterContentInit, QueryList, OnDestroy} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, NG_VALIDATORS,
  Validator, AbstractControl, ValidationErrors } from '@angular/forms';
import { ChoiceOptionComponent } from './choice-option/choice-option.component';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-choice',
  templateUrl: './choice.component.html',
  styleUrls: ['./choice.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: ChoiceComponent, multi: true},
    { provide: NG_VALIDATORS, useExisting: ChoiceComponent, multi: true}
  ]
})
export class ChoiceComponent implements OnInit, AfterContentInit, OnDestroy, ControlValueAccessor, Validator {

  @Input() required: boolean;
  @ContentChildren(ChoiceOptionComponent) choiceOptions: QueryList<ChoiceOptionComponent>;

  value: string;
  selectedSubscription: Subscription;

  constructor() {}

  onChange: any = () => {};
  onTouched: () => void = () => {};

  ngOnInit() {}

  ngOnDestroy(): void {}

  ngAfterContentInit(): void {
    this.choiceOptions.forEach( (option: ChoiceOptionComponent) => {
      this.selectedSubscription = option.valueChanged.subscribe( (value: string) => {
        this.selectOption(value);
        this.markAsSelected(value);
      });
    });

    setTimeout( () => {
      this.markAsSelected(this.value);
    });
  }

  markAsSelected(value: string) {
    this.choiceOptions.forEach( (option: ChoiceOptionComponent) => {
      option.isSelected = option.value === value;
    });
  }

  selectOption(option: string) {
    this.value = option;
    this.onChange(option);
    this.onTouched();
  }

  registerOnChange( fn: any) {
    this.onChange = fn;
  }

  registerOnTouched( fn: any): void {
    this.onTouched = fn;
  }

  writeValue(value: string): void {
    if (value !== undefined) {
      this.value = value;
      if (this.choiceOptions) {
        this.markAsSelected(value);
      }
    }
  }

  setDisabledState(isDisabled: boolean): void {
    if (this.choiceOptions) {
      isDisabled ? this.markAsDisabled() : this.markAsEnabled();
    }
  }

  markAsDisabled() {
    this.choiceOptions.forEach( (option: ChoiceOptionComponent) => {
      option.isDisabled = true;
    });
  }

  markAsEnabled() {
    this.choiceOptions.forEach( (option: ChoiceOptionComponent) => {
      option.isDisabled = false;
    });
  }

  validate(control: AbstractControl): ValidationErrors {
    if (!this.value && this.required) {
      return {
        invalid: true
      };
    } else  {
      return null;
    }
  }
}
