import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Renderer2, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';

import { PopupBase, OpenClose } from '@app/shared/popup';
import { CalculationsService } from '../../../service/calculations/calculations.service';
import { MessageService } from '@app/shared/message';

@Component({
  selector: 'delete-offer-popup',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  animations: [OpenClose],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DeletePopupComponent extends PopupBase implements OnInit, OnDestroy {

  /**
   * Subscription
   */
  private subscriptions = new Subscription();

  constructor(public renderer2: Renderer2,
              public elementRef: ElementRef,
              private messageService: MessageService,
              private calculationsService: CalculationsService) {
    super(renderer2, elementRef);
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Close Click
   */
  closeClick() {
    this.closeSubject.next('close');
  }

  deleteClick() {
    this.calculationsService.delete(this.data.calculatinId);
    this.messageService.add('Porównanie zostało usunięte', 'success');
    this.closeSubject.next('deleted');
  }
}
