import { Component, ElementRef, HostListener, Input, ViewChild } from '@angular/core';

import { config } from '@env/config';
import { NgbTooltip } from '@ng-bootstrap/ng-bootstrap';
import { FormTooltipModel } from '../models/form.model';
@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  @ViewChild('t') ttip: NgbTooltip;

  @Input() public tooltip: FormTooltipModel;
  @Input() public container: string = config.ngbTooltip.container;
  @Input() public placement: string = config.ngbTooltip.placement;

  tooltipIcon = 'assets/icons.svg#info';
  tooltipClass = 'icon icon-xl icon-secondary';

  @HostListener('window:scroll', ['$event.type'])
  onScroll(event: string) {
    if (event === 'scroll' && this.ttip.isOpen()) {
      this.ttip.close();
    }
  }
}
