import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { HttpService } from '../http/http.service';

import { Pagination } from '@app/shared/pagination';
import { CalculationInfo } from '@app/offers/model/offer.model';
import { CalculationsCurrentService } from '../current/current.service';
import { tap } from 'rxjs/operators';

/**
 * Calculations Service
 * Stores Calculations with pagination
 */
@Injectable()
export class CalculationsService {

  /**
   * Is Loaded
   */
  loaded: boolean;

  /**
   * Is Loaded
   */
  _calculations: Array<{calculation: CalculationInfo}> = [];

  /**
   * Pagination
   */
  pagination: Pagination = {offset: 0, limit: 6};

  /**
   * Update Subject
   */
  private updateSubject = new Subject();

  /**
   * Subscriptions
   */
  private subscriptions = new Subscription();

  constructor(private httpService: HttpService,
              private calculationsCurrentService: CalculationsCurrentService) { }

  init() {
    this.loadCalculations();
  }

  destroy() {
    this.subscriptions.unsubscribe();
  }

  delete(refId: string) {
    const calcIndex = this._calculations.findIndex(item => item.calculation.refId === refId);
    this._calculations.splice(calcIndex, 1);
    this.updateSubject.next(true);
    this.subscriptions.add(this.httpService.delete(refId).pipe(
      tap(() => {
        if (this.calculationsCurrentService.calculationId === refId) {
          this.calculationsCurrentService.reset();
        }
      })
    ).subscribe());
  }

  /**
   * Get updates
   */
  get updates() {
    return this.updateSubject.asObservable();
  }

  /**
   * Calculations
   */
  get calculations() {
    return this._calculations.slice(this.pagination.offset, this.pagination.offset + this.pagination.limit);
  }

  /**
   * get Total calculations
   */
  get total() {
    return this._calculations.length;
  }

  /**
   * Load Calculations
   */
  private loadCalculations(): void {
    this.subscriptions.add(this.httpService.getCalculations().subscribe(calculations => {
      this.loaded = true;
      this._calculations = calculations;
      this.filter();
      this.sort();
      this.updateSubject.next(true);
    }));
  }

  /**
   * Sorts Calculations
   */
  private filter(): void {
    // this._calculations = this._calculations.sort((a, b) => {
    //   const aDateTime = a.calculation.updatedTimestamp ? new Date(a.calculation.updatedTimestamp.date).getTime() : (a.calculation.createdTimestamp ? new Date(a.calculation.createdTimestamp.date).getTime() : 0) ;
    //   const bDateTime = b.calculation.updatedTimestamp ? new Date(b.calculation.updatedTimestamp.date).getTime() : (b.calculation.createdTimestamp ? new Date(b.calculation.createdTimestamp.date).getTime() : 0) ;
    //   return bDateTime - aDateTime;
    // });
  }

  /**
   * Sorts Calculations
   */
  private sort(): void {
    this._calculations = this._calculations.sort((a, b) => {
      const aDateTime = a.calculation.updatedTimestamp ? new Date(a.calculation.updatedTimestamp.date).getTime() : (a.calculation.createdTimestamp ? new Date(a.calculation.createdTimestamp.date).getTime() : 0) ;
      const bDateTime = b.calculation.updatedTimestamp ? new Date(b.calculation.updatedTimestamp.date).getTime() : (b.calculation.createdTimestamp ? new Date(b.calculation.createdTimestamp.date).getTime() : 0) ;
      return bDateTime - aDateTime;
    });
  }
}
