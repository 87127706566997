import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-choice-option',
  templateUrl: './choice-option.component.html',
  styleUrls: ['./choice-option.component.scss']
})
export class ChoiceOptionComponent implements OnInit {
  @Input() value: any;
  @Output() valueChanged = new EventEmitter();

  isSelected = false;
  isDisabled = false;

  constructor() { }

  ngOnInit() {
  }

  selectOption() {
    this.valueChanged.emit(this.value);
  }

}
