import { Component, OnInit, Input, HostBinding, Renderer2, ElementRef } from '@angular/core';
import { trigger, transition, style, animate } from '@angular/animations';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [
    trigger('openClose', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.2s cubic-bezier(0.5, 0, 0.1, 1)', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('0.2s cubic-bezier(0.5, 0, 0.1, 1)', style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class LoaderComponent implements OnInit {

  /**
   * Size
   */
  @Input()
  size: string = 'm';

  /**
   * Color
   */
  @Input()
  color: string = 'info';

  /**
   * Bg
   */
  @Input()
  bg: string = 'dark';

  /**
   * Position
   */
  @Input()
  position: string = 'static';

  /**
   * Bg
   */
  @Input()
  rounded?: boolean = false;

  constructor(private elementRef: ElementRef,
              private renderer2: Renderer2) { }

  ngOnInit() {
    if (this.position === 'fixed') {
      this.renderer2.addClass(this.elementRef.nativeElement, 'loader-fixed');
    }
    if (this.position === 'overflow') {
      this.renderer2.addClass(this.elementRef.nativeElement, 'loader-overflow');
    }
  }

  /**
   * Classes
   */
  get classes() {
    return [
      'spinner',
      `spinner-${this.color}`,
      `spinner-${this.size}`,
      `spinner-${this.position}`,
      `spinner-${this.position}-${this.bg}`,
      (this.rounded ? 'spinner-rounded' : '')
    ].filter(item => item).join(' ');
  }

  @HostBinding('@openClose') get() {}

}
