import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { environment } from '@env/environment';
import { CoreModule } from '@app/core';
import { SharedModule, LoaderModule } from '@app/shared';
import { ShellModule } from './shell/shell.module';
import { MessagesModule } from './shared/message';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderService } from './services/header.service';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';

import { ActivateComponent } from './activate/activate.component';
import { RegisterModalComponent } from './modals/register/register.component';
import { ResetModalComponent } from './modals/reset/reset.component';
import { OfflineModule } from './offline';

// Social login
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { ActivatedAccountPopupComponent } from './activate/activated-account-popup/activated-account-popup.component';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ServiceWorkerModule.register('./ngsw-worker.js', { enabled: environment.sw }),
        FormsModule,
        HttpClientModule,
        TranslateModule.forRoot(),
        NgbModule,
        CoreModule,
        SharedModule,
        LoaderModule,
        ShellModule,
        MessagesModule,
        RxReactiveFormsModule,
        OfflineModule,
        SocialLoginModule,
        AppRoutingModule, // must be imported as the last module as it contains the fallback route,
    ],
    declarations: [AppComponent, ActivateComponent, RegisterModalComponent, ResetModalComponent, ActivatedAccountPopupComponent],
    providers: [
        HeaderService,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    // {
                    //     id: GoogleLoginProvider.PROVIDER_ID,
                    //     provider: new GoogleLoginProvider(environment.integrations.googleLoginId),
                    // },
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(
                            environment.integrations.facebookLoginId,
                        ),
                    },
                ],
            } as SocialAuthServiceConfig,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
