import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';
import { BroadcastService } from '../service/broadcast/broadcast.service';
import { Observable } from 'rxjs';

const log = new Logger('AuthenticationGuard');

@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {

  constructor(private router: Router,
              private broadcastService: BroadcastService,
              private credentialsService: CredentialsService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> {
    if (this.credentialsService.isAuthenticated() && this.credentialsService.isAuthenticated()) {
      this.broadcastService.send('authenticated');
    }
    return true;
  }
}
