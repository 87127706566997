import { Component, ElementRef, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

import { PopupBase } from '@app/shared/popup';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-activated-account-popup',
  templateUrl: './activated-account-popup.component.html',
  styleUrls: ['./activated-account-popup.component.scss']
})
export class ActivatedAccountPopupComponent extends PopupBase implements OnDestroy {
    /**
     * Subscription
     */
    private subscriptions = new Subscription();

    constructor(
        public renderer2: Renderer2,
        public elementRef: ElementRef,
        private router: Router
    ) {
        super(renderer2, elementRef);
    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    /**
     * Close Click
     */
    closeClick() {
        this.closeSubject.next('close');
        this.router.navigate(['/login']);
    }

}
