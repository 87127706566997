import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { UserService } from '../service/user/user.service';
import { CredentialsService } from './credentials.service';

@Injectable({
  providedIn: 'root'
})
export class UserAuthenticatedGuard implements CanActivate {

  constructor(private router: Router,
              private credentialsService: CredentialsService,
              private userService: UserService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {

    if (this.credentialsService.isAuthenticated() && this.credentialsService.isRulesAccepted() && this.userService.loaded) {
      return true;
    }

    this.router.navigateByUrl('/');
    return false;
  }
}
