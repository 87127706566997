/**
 * Message Service sends events across system.

 * Messages:
 * 'authenticated' - User was authenticated
 * 'logout' - user logged out
 * 'profile-saved' - Profile Saved
 * 'password-changed' - Password was changed
 * 'calculate' - Calculation was initialized. data: {id: string, token: string},
 * 'calculation-id-received': Calc ID Was Received from API
 */

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

/**
 * Breadcaset Message
 */
export interface BroadcastMessage {
  message: string;
  data?: any;
}

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {

  private broadcastSubject = new Subject<BroadcastMessage>();

  constructor() { }

  /**
   * Send a message/key to subscribers
   * @param message the key message
   */
  send(msg: string, data?: any): void {

    const broadcastMessage = {
      message: msg,
      data: data ? data : null
    };

    this.broadcastSubject.next(broadcastMessage);
  }

  /**
   * Broadcasts
   * @return Observable Subject of messages
   */
  get broadcasts(): Observable<BroadcastMessage> {
    return this.broadcastSubject.asObservable();
  }
}
