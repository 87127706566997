<div class="modal-header text-primary justify-content-center position-relative pt-7 border-0">
    <div class="text-extral font-bold text-center">
        <i class="fas fa-envelope-open-text"></i>
    </div>
    <div (click)="activeModal.dismiss()" class="modal-close position-absolute">
      <i class="far fa-times-circle text-18 text-primary"></i>
    </div>

  </div>
  <div class="modal-body px-4 px-md-9 my-2 text-center">
    <p translate class="text-18">reset.message.withInstruction</p>
    <p translate>reset.check.your.email</p>

    <a translate [routerLink]="['/']" (click)="activeModal.dismiss()">reset.goTo.mainPage</a>
  </div>
