import { Injectable } from '@angular/core';

import { UserService } from '../user/user.service';

/**
 * Unique Key
 */
const UUID = 'calculatorStorage';

/**
 * Storage Service - stores and creates data in local browser storage
 */
@Injectable({
  providedIn: 'root'
})
export class StorageService {

  /**
   * Storage ID
   */
  id: string = UUID;

  constructor(private userService: UserService) { }

  /**
   * Write to local storage
   * @param key key of the storage
   * @param data data to store
   */
  write(key: string, data: any): void {
    this.createKey();
    const saved = JSON.parse(localStorage.getItem(this.id));
    saved[key] = data;
    localStorage.setItem(this.id, JSON.stringify(saved));
  }

  /**
   * Read from local storage
   */
  read(key: string): any {
    this.createKey();
    const saved = JSON.parse(localStorage.getItem(this.id));
    if (saved[key] === undefined) {
      return null;
    }
    return saved[key];
  }

  /**
   * Remove key from storage
   */
  remove(key: string): any {
    if (!this.isKeyCreated()) { return; }
    const saved = JSON.parse(localStorage.getItem(this.id));
    if (saved[key] !== undefined) {
      delete saved[key];
    }
    localStorage.setItem(this.id, JSON.stringify(saved));
  }

  /**
   * Create Key
   */
  private createKey(): void {
    if (this.isKeyCreated()) { return; }
    localStorage.setItem(this.id, JSON.stringify({}));
  }

  /**
   * Check if key is created and valid json
   */
  private isKeyCreated(): boolean {
    // not found
    if (localStorage.getItem(this.id) === null) {
      return false;
    }

    // check if valid json
    try {
      JSON.parse(localStorage.getItem(this.id));
    } catch (e) {
      return false;
    }

    // parsed json is not an object
    if (typeof(JSON.parse(localStorage.getItem(this.id))) !== 'object') {
      return false;
    }

    return true;
  }

}
