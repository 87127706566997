<!-- Headera -->
<app-header></app-header>

<!-- Main -->
<div class="main">
  <router-outlet></router-outlet>
</div>

<!-- Companies Line -->
<app-comapnies></app-comapnies>

<!-- Footer -->
<app-footer></app-footer>

