import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Response } from '@app/models';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private httpClient: HttpClient) { }

  /**
   * Get Referential Id
   */
  createCalculationId() {
    return this.httpClient.get('api/v1/edit-calculation/get-referential-calculation');
  }

  /**
   * Get Calculations
   */
  getCalculations() {
    return this.httpClient.get('api/v1/user/calculations').pipe(
      map((res: Response) => res.data as any)
    );
  }

  /**
   * Get Calculation Structure
   */
  getStructure() {
    return this.httpClient.skipToken().get('api/v1/external/form-fields');
  }

  /**
   * Assign Calculation to User
   */
  assignCalculationToUser(guestToken: string) {
    return this.httpClient.post('api/v1/user/assign-guest-calculations', {guestJWT: guestToken});
  }

  /**
   * Clone Calculation
   */
  clone(calculationId: string) {
    return this.httpClient.post(`api/v1/calculation/${calculationId}/clone`, {}).pipe(
      map((res: Response) => res.data as any)
    );
  }

  /**
   * Reset Calculation - works the same as Clone, but marks the old calculation as deleted
   */
  reset(calculationId: string) {
    return this.httpClient.post(`api/v1/calculation/${calculationId}/reset`, {}).pipe(
      map((res: Response) => res.data as any)
    );
  }

  /**
   * Calculate
   */
  calculate(calculationId: string, payload: any) {
    return this.httpClient.post(`api/v1/external/calculate/${calculationId}`, payload).pipe(
      map((res: Response) => {
        return res.data;
      })
    );
  }

  /**
   * Calculate
   */
  delete(refId: string) {
    return this.httpClient.post(`api/v1/calculation/${refId}/delete`, {});
  }
}
