import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Shell } from '@app/shell/shell.service';
import { ActivateComponent } from './activate/activate.component';
import { extract } from './core';

import { UserGuard, TokenGuard, UserAuthenticatedGuard, NotLoggedUserGuard, LoggedUserGuard } from '@app/core';

const routes: Routes = [
    // default path
    {
        path: '',
        redirectTo: '/',
        pathMatch: 'full',
    },
    {
        // token Gurad
        path: '',
        canActivateChild: [TokenGuard],
        children: [
            {
                // user loaded guard - run's only if user is authenticated. Makes sure if user is authenticated, it will be loaded on all the pages
                path: '',
                canActivate: [UserGuard],
                children: [
                    // routes with Shell
                    Shell.childRoutes([
                        {
                            path: 'user/activate/:mail/:hash',
                            component: ActivateComponent,
                            data: {
                                title: extract('Porównywarka ubezpieczeń samochodów❗ Kalkulator OC/AC❗'),
                            },
                        },
                        {
                            path: '',
                            loadChildren: () =>
                                import('./calculator/calculator.module').then(
                                    (m) => m.CalculatorModule,
                                ),
                        },
                        {
                            path: 'oferty',
                            loadChildren: () =>
                                import('./offers/offers.module').then((m) => m.OffersModule),
                        },
                        {
                            path: 'login',
                            loadChildren: () =>
                                import('./login/login.module').then((m) => m.LoginModule),
                            canActivate: [NotLoggedUserGuard],
                        },
                        {
                            path: 'logout',
                            loadChildren: () =>
                                import('./logout/logout.module').then((m) => m.LogoutModule),
                        },
                        {
                            path: 'register',
                            loadChildren: () =>
                                import('./register/register.module').then((m) => m.RegisterModule),
                            canActivate: [LoggedUserGuard]
                        },
                        {
                            path: '',
                            loadChildren: () =>
                                import('./reset/reset.module').then((m) => m.ResetModule),
                            canActivate: [LoggedUserGuard]
                        },
                        {
                            path: 'test',
                            loadChildren: () =>
                                import('./test/test.module').then((m) => m.TestModule),
                        },
                        {
                          path: 'partner',
                          loadChildren: () =>
                            import('./partner/partner.module').then((m) => m.PartnerModule),
                        },
                        {
                            path: 'profil',
                            canActivate: [UserAuthenticatedGuard],
                            loadChildren: () =>
                                import('./profile/profile.module').then((m) => m.ProfileModule),
                        },
                        {
                            path: 'mail',
                            loadChildren: () =>
                                import('./mail/mail.module').then((m) => m.MailModule),
                        },
                    ]),
                ],
            },
        ],
    },
    // Fallback when no prior route is matched
    { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
    // imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    imports: [RouterModule.forRoot(routes, { enableTracing: false })],
    exports: [RouterModule],
    providers: [],
})
export class AppRoutingModule {}
