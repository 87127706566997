import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';

import { Pagination } from './/pagination.model';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit, OnDestroy {

  /**
   * Pagination Output Emitter
   */
  @Output() pagination: EventEmitter<Pagination> = new EventEmitter<Pagination>();

  /**
   * Total Pages
   */
  @Input() total: number;

  /**
   * Reset
   */
  @Input() limit: number;

  @Input() isDesktop: boolean;

  /**
   * Page Number
   */
  page: number = 0;

  private subscriptions = new Subscription();

  constructor() { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Get Pages
   */
  get pages(): number {
    if (this.total === 0) {
      return 0;
    }
    if (this.total % this.limit === 0) {
      return Math.floor(this.total / this.limit) - 1;
    } else {
      return Math.floor(this.total / this.limit);
    }
  }

  /**
   * Next Click
   */
  nextClick(): void {
    if (this.page !== this.pages) {
      this.page++;
      this.setPagination();
    }
  }

  /**
   * Prev Click
   */
  prevClick(): void {
    if (this.page !== 0) {
      this.page--;
      this.setPagination();
    }
  }

  /**
   * Got to Page
   */
  goToPageClick(page: number): void {
    this.page = page;
    this.setPagination();
  }

  /**
   * Set Pagination
   */
  private setPagination(): void {
    this.pagination.emit({
      offset: this.calcOffset(),
      limit: this.calcLimit()
    });
  }

  /**
   * Calculate Offset by active page
   */
  private calcOffset(): number {
    if (this.total === 0) {
      return 0;
    }
    return this.page * this.limit;
  }

  /**
   * Calculate Limit
   */
  private calcLimit(): number {
    return this.limit;
  }

}
