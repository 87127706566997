<div *ngIf="total > 0" class="text-center text-0">

    <div class="d-inline-flex align-items-center no-gutters text-uppercase text-12 text-default">
      <div class="p-1 square mr-lg-2 rounded-light" (click)="prevClick()" [ngClass]="{ 'pointer bg-hover-lighter': page !== 0, 'circle-muted': page === 0 }" translate>
        <div class="icon icon-primary d-block rotate-180" [ngClass]="{ 'icon-default': page !== 0, 'icon-muted': page === 0 }" inlineSVG="assets/icons.svg#next"></div>
      </div>

      <ng-container *ngIf="isDesktop; else mobile">
          <div class="mx-0 mx-lg-2 square bg-hover-lighter p-1 pointer" *ngIf="page-2 > 0" (click)="goToPageClick(0)">1</div>
          <div class="mx-0 mx-lg-2 square" *ngIf="page-3 > 0">...</div>

          <div class="mx-0 mx-lg-2 square bg-hover-lighter rounded-light p-1 pointer" *ngIf="page-2 >= 0" (click)="goToPageClick(page-2)">{{page-2+1}}</div>
          <div class="mx-0 mx-lg-2 square bg-hover-lighter rounded-light p-1 pointer" *ngIf="page-1 >= 0" (click)="goToPageClick(page-1)">{{page-1+1}}</div>
          <div class="mx-0 mx-lg-2 square rounded-light p-1 current font-medium text-secondary" >{{page+1}}</div>
          <div class="mx-0 mx-lg-2 square bg-hover-lighter rounded-light p-1 pointer" *ngIf="page+1 <= pages" (click)="goToPageClick(page+1)">{{page+1+1}}</div>
          <div class="mx-0 mx-lg-2 square bg-hover-lighter rounded-light p-1 pointer" *ngIf="page+2 <= pages" (click)="goToPageClick(page+2)">{{page+2+1}}</div>

          <div class="mx-0 mx-lg-2 square" *ngIf="page+3 < pages">...</div>
          <div class="mx-0 mx-lg-2 square p-1 active pointer" *ngIf="page+2 < pages" (click)="goToPageClick(pages)">{{pages + 1}}</div>
      </ng-container>

      <ng-template #mobile>

        <div class="mx-0 mx-lg-2 square rounded-light p-1 current font-medium text-secondary">{{page+1}}</div>

        <div class="mx-0 mx-lg-2 square" *ngIf="page<= pages">...</div>

        <div class="mx-0 mx-lg-2 square p-1 active pointer" *ngIf="page <= pages" (click)="goToPageClick(pages)">{{pages + 1}}</div>

      </ng-template>

      <div class="p-1 square ml-lg-2 rounded-light" (click)="nextClick()" [ngClass]="{ 'pointer bg-hover-lighter': page !== pages, 'circle-muted': page === pages }" translate>
        <div class="icon icon-default d-block" [ngClass]="{ 'icon-default': page !== pages, 'icon-muted': page === pages }" inlineSVG="assets/icons.svg#next"></div>
      </div>

    </div>
</div>
