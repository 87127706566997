import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger } from '../logger.service';
import { MessageService } from '@app/shared/message';
import { ConnectionService } from '@app/offline';

const log = new Logger('ErrorHandlerInterceptor');

/**
 * Adds a default error handler to all requests.
 */
@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private connectionService: ConnectionService,
              private messageService: MessageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(error => this.errorHandler(error)));
  }

  // Customize the default error handler here if needed
  private errorHandler(response: HttpErrorResponse): Observable<HttpEvent<any>> {
    if (!environment.production) {
      // Do something with the error
    }

    //
    this.showErrorMessage(response);

    throw response;
  }

  /**
   * Show Error Message
   * @param response Error Reposne
   */
  private showErrorMessage(response: HttpErrorResponse) {

    this.connectionService.add();

    // error codes
    switch (response.status) {
      case 400:
        if (response.error.message) {
          this.messageService.add(response.error.message , 'danger');
        } else {
          this.messageService.add('Przepraszamy, pojawił się błąd. Spróbuj ponownie.' , 'danger');
        }
        break;
      case 401:
      case 403:
      case 404:
      case 422:
        // do nothing
        break;
        case 500:
            if (response.error.error.length) {
                this.messageService.add(response.error.error[0].message, 'danger')
            } else {
                this.messageService.add('Przepraszamy, pojawił się błąd. Spróbuj ponownie.' , 'danger');
            }
        break;
      default:
        this.messageService.add('Przepraszamy, pojawił się błąd. Spróbuj ponownie.' , 'danger');
        break;
    }
  }

}
