import { Component, OnInit, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { Router, Event, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

import { MessageService } from './service/messages.service';
import { Message } from './model/message.model';
import { SlideInOut } from './animation/slide-in-out.animation';
import { PopupCountService } from '../popup';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  animations: [SlideInOut],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MessagesComponent implements OnInit, OnDestroy {

  top: boolean = false;

  /**
   * Subscriptions
   */
  private subscriptions = new Subscription();

  constructor(private router: Router,
              private popupCountService: PopupCountService,
              private messageService: MessageService) { }

  ngOnInit() {
    this.subscriptions.add(this.popupCountService.count$.subscribe(count => this.top = count === 0 ? false : true));
    // this.subscriptions.add(this.router.events.subscribe((event: Event) => this.routeChange(event)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Get Messages
   * @return Message Model or null if no messages available
   */
  get messages(): Array<Message> {
    return this.messageService.messages;
  }

  /**
   * Has Messages
   */
  get hasMessages(): boolean {
    return this.messages.length > 0;
  }

  /**
   * Mark As Read
   */
  markAsRead(index: number) {
    this.messageService.removeAtIndex(index);
  }

  /**
   * Route Change Event
   */
  private routeChange(event: Event): void {
    if (event instanceof NavigationStart) {
      this.messageService.cleanAndRestoreRouteMessages();
    }
  }

}
