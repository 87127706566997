<div class="pb-4">
  <div class="d-flex align-items-center rounded px-4 py-4 text-white" [ngClass]="['bg-' +  message.type]">
    <div class="icon icon-xxl icon-white pointer mr-4" [inlineSVG]="'assets/icons.svg#info'"></div>
    <div class="flex-grow-1 text-white">{{ message.content }}</div>
    <div class="message-close-btn pointer ml-3" (click)="markAsRead(index)"></div>
  </div>
</div>




