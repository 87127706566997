import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

import { Message } from '../model/message.model';
import { interval, Subscription } from 'rxjs';
import { timeout, tap } from 'rxjs/operators';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})
export class MessageComponent implements OnInit, OnDestroy {

  /**
   * Message
   */
  @Input()
  message: Message;

  /**
   * Message Index
   */
  @Input()
  index: number;

  /**
   * Message Index
   */
  @Output()
  markAsReadEvent = new EventEmitter();

  /**
   * Seconds before close
   */
  private seconds = interval(3000);

  /**
   * Subscriptions
   */
  private subscriptions = new Subscription();

  constructor() { }

  ngOnInit() {
    this.subscriptions.add(this.seconds.pipe().subscribe(() => this.markAsReadEvent.emit(this.index)));
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Mark displayed message as read
   */
  markAsRead(index: number): void {
    this.markAsReadEvent.emit(index);
  }

}
