import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { Subscription, Subject, forkJoin, of, Observable, throwError } from 'rxjs';
import { tap, switchMap, finalize, catchError } from 'rxjs/operators';
import { CalculationsCurrentService } from '../current/current.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StorageService } from '@app/core';
import { Router } from '@angular/router';

@Injectable()
export class CalculationsRefreshService {

  /**
   * Is Loading
   */
  loading = false;

  /**
   * Subscriptions
   */
  private subscriptions = new Subscription();

  /**
   * Update Subject
   */
  private updateSubject: Subject<boolean> = new Subject();

  constructor(private httpService: HttpService,
              private router: Router,
              private storageService: StorageService,
              private calculationsCurrentService: CalculationsCurrentService) { }

  init() {}

  destroy() {
    this.subscriptions.unsubscribe();
  }

  /**
   * Get update
   */
  get update() {
    return this.updateSubject.asObservable();
  }

  /**
   * Clone Calculation
   * @param string Calculation Id
   */
  refresh(calculationId: string) {

    if (this.loading) { return; }

    // show loader
    this.loading = true;
    this.updateSubject.next(true);

    // subscription
    // return this.httpService.reset(calculationId).pipe(
    return this.httpService.reset(calculationId).pipe(
      tap((data) => {
        this.calculationsCurrentService.calculationId = data.calculation.refId;
      }),
      switchMap((data: any) => {
        return forkJoin([of(data.calculation.refId), this.httpService.calculate(data.calculation.refId, data.params)]);
      }),
      tap((data) => {
        this.calculationsCurrentService.calculationId = data[0];
        this.calculationsCurrentService.token = data[1].notificationToken;
      }),
      finalize(() => {
        this.loading = false;
        this.updateSubject.next(true);
      }),
      catchError((error: HttpErrorResponse) => {
        const caclId = this.calculationsCurrentService.calculationId;
        this.calculationsCurrentService.reset();
        this.calculationsCurrentService.calculationId = caclId;
        this.storageService.write('skip-refresh-message', true);
        this.storageService.write('show-calculator-errors', true);
        this.router.navigate(['/']);
        return throwError(error);
      })
    );
  }
}
