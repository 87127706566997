import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { share, tap, map, catchError } from 'rxjs/operators';

import { BroadcastService } from '../service/broadcast/broadcast.service';
import { CredentialsService } from './credentials.service';
import { MessageService } from '@app/shared/message';

export interface LoginContext {
    username: string;
    password: string;
    remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
    providedIn: 'root',
})
export class AuthenticationService {
    API_URL: string = 'api/v1/auth';

    constructor(
        private messageService: MessageService,
        private broadcastService: BroadcastService,
        private credentialsService: CredentialsService,
        private httpClient: HttpClient,
    ) {}

    /**
     * Authenticates the user.
     * @param context The login parameters.
     * @return The user credentials.
     */
    login(context: LoginContext): Observable<any> {
        const payload = {
            username: context.username,
            password: context.password,
        };
        return this.httpClient.skipToken().post('api/v1/auth/login', payload);
    }

    /**
     * Logs out the user and clear credentials.
     * @return True if the user was logged out successfully.
     */
    logout(): Observable<boolean> {
        // Customize credentials invalidation here
        this.credentialsService.setCredentials();
        this.broadcastService.send('logout');
        return of(true);
    }

    /**
     * Refresh
     */
    refresh(): Observable<any> {
        const rulesAndPolitics = this.credentialsService.rulesAndPolitics;
        return this.httpClient
            .skipToken()
            .post('api/v1/auth/token/refresh', {
                refresh_token: this.credentialsService.refreshToken,
            })
            .pipe(
                share(),
                tap((credentials: { token: string; refresh_token: string; }) => {
                    this.credentialsService.setTokens({...credentials, rulesAndPolitics});
                }),
                catchError((error) => {
                    if (error.status === 401) {
                      this.messageService.add('Sesja wygasła. Zaloguj się ponownie.', 'info');
                    } else {
                      this.messageService.add('Wystąpił błąd.', 'danger');
                    }
                    return throwError(error);
                }),
            );
    }

    /**
     * Activate User
     * @param email user email
     * @param hash hash from url
     */
    activate(email: string, hash: string) {
        const payload = {
            email,
            hash,
        };
        return this.httpClient.skipToken().post('api/v1/auth/user/verify', payload);
    }

    /**
     * Refresh
     */
    identifyGuest(): Observable<boolean> {
        this.credentialsService.clearGuestTokens();

        const guestUUID = this.credentialsService.guestUUID;
        return this.httpClient
            .skipToken()
            .post('api/v1/auth/guest', { guestUuid: guestUUID })
            .pipe(
                tap((res: any) => {
                    this.credentialsService.setGuestTokens(res.data.guestToken);
                }),
                map((_) => {
                    return true;
                }),
                catchError((error) => {
                    this.credentialsService.clearGuestTokens();
                    return throwError(error);
                }),
            );
    }

    socialLogin(provider: string, token: string) {
        return this.httpClient.skipToken().post(`${this.API_URL}/${provider}`, {token: token});
    }

    googleLogin(googleCredential: string) {
      return this.httpClient.skipToken().post(`${this.API_URL}/google`, { token: googleCredential });
    }
}
