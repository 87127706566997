<header class="main-header" [class.shadow]="!(menuShow | async)">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">

      <!-- Logo -->
      <a class="d-inline-block logo" (click)="logoClick()" href="{{ externalUrl }}" translate>
        <img class="logo" alt="Logo Pasazubezpieczeniowy.online – Kalkulator ubezpieczeń" [src]="logoUrl" />
      </a>

      <div class="d-none d-xl-flex align-items-center pt-4">
        <!-- Kalulator -->
        <a class="btn btn-secondary btn-borderless px-3 mr-2" (click)="calculatorClick()" routerLink="/" routerLinkActive #rlaCalc="routerLinkActive" [ngClass]="rlaCalc.isActive ? 'btn-active': ''">
          <span class="text-uppercase text-15 text-mp-red-2">Kalkulator OC/AC</span>
        </a>
        <ul class='menu'>
          <li>
            <a class="btn btn-secondary btn-borderless px-3 mr-2" href="{{ externalUrl }}">
              <span class="text-uppercase text-15 text-mp-blue">Warto Wiedzieć</span>
            </a>
            <ul class='sub-menu'>
              <li>
                <a href="{{ externalUrl }}jak-dobrze-ubezpieczyc-samochod/">
                  Jak dobrze ubezpieczyć samochód
                </a>
              </li>
              <li>
                <a href="{{ externalUrl }}ubezpieczenie-oc/">
                  Ubezpieczenie OC
                </a>
              </li>
              <li>
                <a href="{{ externalUrl }}ubezpieczenie-autocasco/">
                  Ubezpieczenie AUTOCASCO
                </a>
              </li>
              <li>
                <a href="{{ externalUrl }}ubezpieczenie-nnw/">
                  Ubezpieczenie NNW
                </a>
              </li>
              <li>
                <a href="{{ externalUrl }}ubezpieczenie-assistance/">
                  Ubezpieczenie Assistance
                </a>
              </li>
              <li>
                <a href="{{ externalUrl }}slownik/">
                  Słownik ubezpieczeń
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <a class="btn btn-secondary btn-borderless px-3 mr-2" href="{{ externalUrl }}/ubezpieczyciele/">
          <span class="text-uppercase text-15 text-mp-blue">Ubezpieczyciele</span>
        </a>

        <a class="btn btn-secondary btn-borderless px-3 mr-2" href="{{ externalUrl }}/kontakt" >
          <span class="text-uppercase text-15 text-mp-blue">Kontakt</span>
        </a>

        <div class="align-self-stretch py-1">
            <div class="vline vline-primary"></div>
        </div>

        <a *ngIf="!isAuthenticated" [routerLink]="['/login']" routerLinkActive="btn-active" class="btn btn-primary btn-borderless px-2 ml-4 text-mp-blue-2">
          <span class="text-uppercase text-15" translate>Login</span>
        </a>



        <ng-container *ngIf="isAuthenticated">

          <a class="btn btn-secondary btn-borderless px-3 ml-2 mr-2" routerLink="/profil" routerLinkActive #rlaProfile="routerLinkActive" [ngClass]="rlaProfile.isActive ? 'btn-active': ''">
            <span class="text-uppercase text-15" translate>Moje konto</span>
          </a>
          <a [routerLink]="['/logout']" class="btn btn-primary px-3 btn-borderless">
            <span class="text-uppercase text-15" translate>Logout</span>
          </a>
        </ng-container>

      </div>
      <div class="d-xl-none">
        <app-burger></app-burger>
      </div>

    </div>
  </div>

  <div class="d-lx-none text-center mt-3 pt-5 pb-5 dropdown-shadow" *ngIf="menuShow | async">

    <a class="mb-2 text-14 text-uppercase d-block py-2" routerLink="/" (click)="hide()" routerLinkActive #rlaCalcMobile="routerLinkActive" [ngClass]="rlaCalcMobile.isActive ? 'text-secondary': 'text-primary'">
      <span class="link text-secondary" translate>Kalkulator OC/AC</span>
    </a>

    <ul class='menu menu-mobile'>
      <li>
        <input type='checkbox' id='menu-checkbox' />
        <label for="menu-checkbox" class="btn btn-secondary btn-borderless px-3 mr-2 text-uppercase text-15 text-mp-blue">
          Warto widzieć
        </label>
        <ul class='sub-menu'>
          <li>
            <a href="{{ externalUrl }}jak-dobrze-ubezpieczyc-samochod/">
              Jak dobrze ubezpieczyć samochód
            </a>
          </li>
          <li>
            <a href="{{ externalUrl }}ubezpieczenie-oc/">
              Ubezpieczenie OC
            </a>
          </li>
          <li>
            <a href="{{ externalUrl }}ubezpieczenie-autocasco/">
              Ubezpieczenie AUTOCASCO
            </a>
          </li>
          <li>
            <a href="{{ externalUrl }}ubezpieczenie-nnw/">
              Ubezpieczenie NNW
            </a>
          </li>
          <li>
            <a href="{{ externalUrl }}ubezpieczenie-assistance/">
              Ubezpieczenie Assistance
            </a>
          </li>
          <li>
            <a href="{{ externalUrl }}slownik/">
              Słownik ubezpieczeń
            </a>
          </li>
        </ul>
      </li>
    </ul>

    <a class="mb-2 text-14 text-secondary text-uppercase d-block py-2" href="{{ externalUrl }}/ubezpieczyciele/" (click)="hide()">
      <span class="link text-secondary">Ubezpieczyciele</span>
    </a>

    <a class="mb-2 text-14 text-uppercase d-block py-2" href="{{ externalUrl }}/kontakt/" (click)="hide()">
      <span class="link text-secondary">Kontakt</span>
    </a>

    <hr class="menu-item-separator">

    <a *ngIf="isAuthenticated" class="mb-2 text-14 text-secondary py-2 text-uppercase d-block" routerLink="/profil" (click)="hide()" routerLinkActive #rlaProfileMobile="routerLinkActive" [ngClass]="rlaProfileMobile.isActive ? 'text-secondary': 'text-primary'">
      <span translate>Moje konto</span>
    </a>

    <a *ngIf="isAuthenticated" [routerLink]="['/logout']" (click)="hide()" class="text-14 py-2 text-uppercase d-block">
      <span translate>Logout</span>
    </a>

    <a *ngIf="!isAuthenticated" routerLink="/login" routerLinkActive="active" (click)="hide()" class="text-14 py-2 text-uppercase d-block">
      <span translate>Login</span>
    </a>

  <ul class="info-menu__wrapper">
    <li class="info-menu__item">
      <ng-container *ngIf="(offerNumber$ | async) as offer">
        <ng-container *ngIf="isAuthenticated else notLogged">
          <div>
            Numer porównania {{ offer?.number }}
          </div>
        </ng-container>
        <ng-template #notLogged>
          <a routerLink="/login" (click)="hide()">Zapisz porównanie nr {{ offer?.number }}</a>
        </ng-template>
      </ng-container>
    </li>
    <li class="info-menu__item">
      <a href="tel:+48515517515">Zadzwoń, jeśli masz pytania <span class="text-secondary font-bold ml-2"> 515 517 515</span></a>
    </li>
  </ul>
  </div>
</header>
