<app-popup-wrapper
  iconName="circled-tick"
  title="Gotowe!"
  [content]="content"
  (closeClicked)="closeClick()">

    <ng-template #content>
      <span content style='font-weight: 300;'>Twoje konto na Pasazubezpieczeniowy.online <span class="text-mp-blue-2">jest aktywne :)</span><br/>
          Zaloguj się i skorzystaj z jego możliwości. </span>
    </ng-template>

</app-popup-wrapper>
