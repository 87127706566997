/**
 * Sync between Store and CalculationsCurrentService
 */

import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StoreSyncService {

  private idSubject = new Subject<string>();
  private resetSubject = new Subject<boolean>();

  constructor() { }

  /**
   * Reset Calc
   */
  clear(): void {
    this.resetSubject.next(true);
  }

  /**
   * Sen
   * @param id calcId
   */
  setCalcId(id: string): void {
    this.idSubject.next(id);
  }

  /**
   * Message
   * @return Observable Subject of id
   */
  get id(): Observable<string> {
    return this.idSubject.asObservable();
  }

  /**
   * Reset
   */
  get reset(): Observable<boolean> {
    return this.resetSubject.asObservable();
  }
}
