<!-- box-popup -->
<div class="relative rounded box-popup shadow-popup">

  <div class="relative box-popup">
    <div class="p-8">

      <!-- Title -->
      <div class="text-center mb-7">
        <div class="text-24 text-primary">Czy na pewno chcesz trwale usunąć to porównanie?</div>
      </div>

      <div class="row align-items-center justify-content-center">
        <div class="col-md text-right">
          <div class="btn btn-primary d-block mb-3 mb-md-0 d-md-inline px-10" (click)="closeClick()">Wróć</div>
        </div>
        <div class="col-md">
          <div class="btn btn-theme d-block d-md-inline px-10" (click)="deleteClick()">Usuń</div>
        </div>
      </div>

    </div>

    <!-- Close Button -->
    <div class="popup-close-btn" (click)="closeClick()"></div>
  </div>

</div>
