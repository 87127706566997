import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pln'
})
export class CurrencyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    value = value.toString();
    let restFontSize = 21;
    if (typeof args !== 'undefined' && args.restFontSize !== 'undefined') {
      restFontSize = args.restFontSize;
    }
    if (!value.includes('.')) {
      value += `<span class="text-${restFontSize}">,00 zł</span>`;
    } else {
      value = value.replace('.', ',');
      const parts = value.split(',');
      value = parts[0] + `<span class="text-${restFontSize}">,${parts[1]} zł</span>`;
    }
    return value;
  }

}
