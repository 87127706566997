import { Component, OnInit} from '@angular/core';
import Swiper, { Autoplay } from 'swiper';

Swiper.use([Autoplay]);

@Component({
  selector: 'app-comapnies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  swiper: Swiper;

  constructor() { }

  ngOnInit() {

    this.swiper = new Swiper('.mySwiper',
      {
        slidesPerView: 8,
        loop: true,
        speed: 1700,
        spaceBetween: 30,
        freeMode: true,
        allowTouchMove: false,
        autoplay: {
          delay: 1000,
        },
        breakpoints: {
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            spaceBetween: 10
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 20
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 20
          },
          1100: {
            slidesPerView: 6,
            spaceBetween: 30
          },
          1300: {
            slidesPerView: 8,
            spaceBetween: 30
          }
        },
      }
    );
  }
}
