<div class="ml-3">
  <ng-container *ngIf="!tooltip?.image else imgTooltip">
    <button
      #t="ngbTooltip"
      [ngbTooltip]="tooltip?.text"
      [container]="container"
      [placement]="placement"
      class="{{ tooltipClass }}"
      inlineSVG="{{ tooltipIcon }}"
    ></button>
  </ng-container>
  <ng-template #imgTooltip>
    <ng-template #htmlContent>
      <span>{{ tooltip.text }}</span>
      <img class="img-fluid" src="{{ tooltip?.image }}" alt="" />
    </ng-template>
    <button
      #t="ngbTooltip"
      [ngbTooltip]="htmlContent"
      [container]="container"
      [placement]="placement"
      class="{{ tooltipClass }}"
      inlineSVG="{{ tooltipIcon }}"
    ></button>
  </ng-template>
</div>
