<div *ngIf="loaded && hasCalculations" class="mb-6">

    <ng-container *ngIf="isDesktop; else mobile">
        <!-- List -->
        <div class="mb-6">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12" *ngFor="let calculation of calculations">
                    <app-calculation-box [calculation]="calculation.calculation"></app-calculation-box>
                </div>
            </div>
        </div>
        <!-- Pagination -->
        <div class="row">
            <div class="col-12 mb-6">
                <app-pagination [total]="total" [limit]="limit" [isDesktop]="isDesktop" (pagination)="setPagination($event)"></app-pagination>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div (click)="newCalculationClicked()" class="btn btn-theme">Nowe porównanie</div>
            </div>
        </div>
    </ng-container>

    <ng-template #mobile>
        <!-- Pagination / new calculation-->
        <div class="row align-items-center mb-5">
            <div class="col-6">
                <div (click)="newCalculationClicked()" class="btn btn-theme">Nowe porównanie</div>
            </div>
            <div class="col-6">
                <app-pagination [total]="total" [limit]="limit" [isDesktop]="isDesktop" (pagination)="setPagination($event)"></app-pagination>
            </div>
        </div>
        <!-- List -->
        <div class="mb-6">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-12" *ngFor="let calculation of calculations">
                    <app-calculation-box [calculation]="calculation.calculation"></app-calculation-box>
                </div>
            </div>
        </div>
    </ng-template>


</div>

<div *ngIf="!loaded" class="text-center py-8">
  <app-loader size="l"></app-loader>
</div>


