import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

import { StorageService, BroadcastService } from '@app/core';

/**
 * Calculation Current Service
 * Stores current data in service and session/local storage to prevent using ID's in URLs
 */
@Injectable()
export class CalculationsCurrentService {

  /**
   * Current calculation Id
   */
  _calculationId: string = null;

  /**
   * Token
   */
  _token: string = null;

  /**
   * Subscriptions
   */
  private subscriptions = new Subscription();

  constructor(
    private storageService: StorageService,
    private broadcastService: BroadcastService
  ) { }

  init(): void {

    // restore values from local storage
    this.restore();

    // subscribe to logout - reset
    this.subscriptions.add(this.broadcastService.broadcasts.pipe(
      filter(data => data.message === 'logout'))
    .subscribe(_ => this.reset()));

  }

  destroy(): void {
    this.subscriptions.unsubscribe();
  }

  /**
   * Set Calc ID
   * @param id calc Id
   */
  set calculationId(id: string) {
    this._calculationId = id;
    this.save();
  }

  /**
   * Get Calc ID
   */
  get calculationId() {
    return this._calculationId;
  }

  /**
   * Set Token
   * @param id calc Id
   */
  set token(token: string) {
    this._token = token;
    this.save();
  }

  /**
   * Get Token
   */
  get token() {
    return this._token;
  }

  /**
   * Has Calculation
   */
  get hasCalculation(): boolean {
    return this.calculationId !== null && this.calculationId !== undefined;
  }

  /**
   * Reset Data
   */
  reset(preserveId: boolean = false): void {
    const id = this.calculationId;
    this.calculationId = null;
    this.token = null;
    if (preserveId) {
      this.calculationId = id;
    }
  }

  /**
   * Save data to Storage
   */
  private save(): void {
    this.storageService.write('current-calculation', {
      calculationId: this._calculationId,
      token: this._token
    });
  }

  /**
   * Restore from storage
   */
  private restore(): void {
    const data = this.storageService.read('current-calculation');
    if (data === null) { return; }
    this._calculationId = data.calculationId;
    this._token = data.token;
  }
}
