import { Injectable } from '@angular/core';

import { Message } from '../model/message.model';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  /**
   * Array of Messages
   */
  _messages: Array<Message> = [];

  /**
   * Route Messages
   */
  _messagesRouter: Array<Message> = [];

  constructor() { }

  /**
   * Adds Message to the end of array messages or route messages
   * @param message the messagfe
   * @param type Type: success, info, warning, danger
   * @param route if set to true, message will be shown on the first route change
   */
  add(message: string, type: string, route: boolean = false) {
    if (route) {
      this._messagesRouter.push({
        content: message,
        type,
      });
    } else {
      this._messages.push({
        content: message,
        type,
      });
    }
  }

  /**
   * Get Messages
   */
  get messages() {
    return this._messages;
  }

  /**
   * Removes one message from the beginning of the array
   */
  removeAtIndex(index: number): void {
    this._messages.splice(index, 1);
  }

  /**
   * Clean all messages
   */
  clean(): void {
    this._messages = [];
  }

  /**
   * Clean messages and restore route Messages if not empty
   */
  cleanAndRestoreRouteMessages(): void {
    if (this._messages.length !== 0) {
      this._messages = [];
    }
    if (this._messagesRouter.length !== 0) {
      this._messages = this._messagesRouter;
      this._messagesRouter = [];
    }
  }

}
