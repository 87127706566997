import { Injectable } from '@angular/core';
import {
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
} from '@angular/router';

import { Observable } from 'rxjs';

import { UserService } from '../service/user/user.service';
import { CredentialsService } from './credentials.service';

@Injectable({
    providedIn: 'root',
})
export class NotLoggedUserGuard implements CanActivate {
    constructor(
        private router: Router,
        private credentialsService: CredentialsService,
        private userService: UserService,
    ) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.credentialsService.isAuthenticated() && this.userService.loaded) {
            this.router.navigateByUrl('/');
            return false;
        }

        return true;
    }
}
