<footer class="text-white bg-primary">
  <div class="pt-4 pb-4 pt-lg-8 pb-lg-6">
    <div class="container">
      <div class="row">
        <div class="col-lg-4 col-12 text-lg-left text-center">
            <img class="logo mb-lg-0 mb-7 mb-lg-8" alt="Logo Pasazubezpieczeniowy.online – Kalkulator ubezpieczeń" [src]="logoUrl" />
        </div>
        <div class="col-lg-8 col-12">
          <div class="row text-lg-left text-center">
            <div class="col-lg-4 col-12">
              <ul class="footer-nav d-flex flex-lg-column justify-content-center">
                <li class="mb-4 mr-4">
                  <a href="{{ externalUrl }}/o-nas" target='_blank' class="text-uppercase text-white link text-14">O nas</a>
                </li>
                <li class="mb-4 mr-4">
                  <a href="{{ externalUrl }}/kontakt" target='_blank' class="text-uppercase text-white link text-14">Kontakt</a>
                </li>
                <li class="mb-4 mr-4">
                  <a href="{{ externalUrl }}/ubezpieczyciele/" target='_blank' class="text-uppercase text-white link text-14">Ubezpieczyciele</a>
                </li>
                <li class="mb-4 mr-4">
                  <a href="{{ mpUrl }}/dlaczego-warto/" target='_blank' class="text-uppercase text-white link text-14">Współpraca</a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-12">
              <ul class="footer-nav d-flex flex-lg-column justify-content-center flex-wrap">
                <li class="mb-4 mr-4">
                  <a href="{{ mpUrl }}/wzory-dokumentow-do-pobrania/" target='_blank' class="text-uppercase text-white link text-14">Do pobrania</a>
                </li>
                <li class="mr-4">
                  <div class="mb-4">
                    <a class="text-uppercase text-white link text-14" target='_blank' href="{{externalUrl}}oferta-ubezpieczen/">oferta ubezpieczeń</a>
                  </div>
                </li>
                <li class="mr-4">
                  <div class="mb-4">
                    <a class="text-uppercase text-white link text-14" target='_blank' href="{{externalUrl}}jak-dobrze-ubezpieczyc-samochod/">Jak dobrze ubezpieczyć auto</a>
                  </div>
                </li>
                <li>
                  <a href="https://wizytowka.rzetelnafirma.pl/H5BTH9XHEBC6KQEZ/Fulcrum" target='_blank'>
                    <img src="assets/rzetelna-firma_white.svg" alt="Rzetelna Firma" />
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-lg-4 col-12">
              <ul class="footer-nav d-flex flex-lg-column justify-content-center">
                <li class="mb-4 mr-4">
                  <a href="https://pasazubezpieczeniowy.online" class="text-secondary link text-14">Pasazubezpieczeniowy.online</a>
                </li>
              </ul>
            </div>
            <div class='mt-4 col-lg-12 col-12'>
              <div class='text-white text-12'><a href="https://multipolisa.pl/regulamin/" class='text-white' target='_blank'>Regulamin</a> ⓒPasaż Ubezpieczeniowy Sp. z o.o.</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row d-lg-none">
        <div class="col-12 text-center pt-4">
          <a href="{{mpUrl}}/regulamin/" class=" text-12 text-white link mr-3">Regulamin</a>
          <a href="{{externalUrl}}" class=" text-12 text-white link">&copy; Pasazubezpieczeniowy.online</a>
        </div>
      </div>
    </div>
  </div>
  <div [style.color]="'#1a64c3'" class="text-10 text-right mr-1 py-1">{{ buildDate }}</div>
</footer>
