import { Injectable, NgZone } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const mobileBreakPoint = 991;

@Injectable({
  providedIn: 'root'
})
export class RwdService {

  /**
   * Mobile falg
   */
  mobile: boolean;

  /**
   * Rwd Subject
   */
  private rwdSubject = new Subject<boolean>();

  constructor(private ngZone: NgZone) {
    this.mobile = false;
    this.check();
    this.watch();
  }

  private watch() {
    this.ngZone.runOutsideAngular(() => {
      window.addEventListener('resize', this.onWindowResize, false);
    });
  }

  /**
   * Window Resize Event
   */
  private onWindowResize = (event: Event): void => {
    this.check();
  }

  /**
   * Check Windows Size
   */
  private check(): void {
    if (window.innerWidth <= mobileBreakPoint && this.mobile === false) {
      this.ngZone.run(() => {
        this.mobile = true;
        this.rwdSubject.next(this.mobile);
      });
    } else if (window.innerWidth > mobileBreakPoint && this.mobile === true) {
      this.ngZone.run(() => {
        this.mobile = false;
        this.rwdSubject.next(this.mobile);
      });
    }
  }

  /**
   * Rwd Evengt
   */
  get update(): Observable<boolean> {
    return this.rwdSubject.asObservable();
  }

}
