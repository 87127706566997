import { Injectable } from '@angular/core';
import { Router, CanActivateChild, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { CredentialsService } from './credentials.service';
import { AuthenticationService } from './authentication.service';

/**
 * Makes sure a Guest or User Token exists on App init
 */
@Injectable({
  providedIn: 'root'
})
export class TokenGuard implements CanActivateChild, CanActivate {

  constructor(private router: Router,
              private authenticationService: AuthenticationService,
              private credentialsService: CredentialsService) { }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (!this.credentialsService.isAuthenticated() && !this.credentialsService.isGuestReady()) {
      localStorage.removeItem('calculatorStorage');
    }

    // is authenticated
    if (this.credentialsService.isAuthenticated() && this.credentialsService.isRulesAccepted()) {
      return true;
    }

    // is guest ready
    if (this.credentialsService.isGuestReady()) {
      return true;
    }

    // identify guest
    return this.authenticationService.identifyGuest();

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    this.router.navigate(['/']);
    return true;
  }
}
