<div class="relative shadow rounded-light bg-white border border-light p-4 mb-6" [@Enter]>
  <div class="d-flex">
    <!-- Icon -->
    <div class="mr-4">
      <div class="icon icon-xxxl icon-secondary" inlineSVG="assets/icons.svg#gordini"></div>
    </div>
    <!-- Text + Button -->
    <div class="flex-grow-1">
      <div class="text-default py-2 text-13">

        <div class="mb-1 text-secondary">{{ calculation.vehicleMake ? calculation.vehicleMake : 'BRAK' }}</div>
        <div class="mb-3 mb-lg-6">{{ calculation.vehicleModel ? calculation.vehicleModel : 'BRAK' }}</div>
        <div class="mb-6">Numer porównania: {{ calculation.number ? calculation.number : 'BRAK' }}</div>

        <div *ngIf="isDone" class="btn btn-theme btn-s" (click)="setCalculationClick()">Zobacz oferty</div>
        <div (click)="editClick()" *ngIf="!isDone" class="btn btn-primary btn-s">Uzupełnij dane</div>
      </div>
    </div>
  </div>
  <!-- Icon -->
  <div class="icon-delete pointer" (click)="deletePopupClick()">
    <div class="icon icon-xl icon-dark" inlineSVG="assets/icons.svg#bin"></div>
  </div>
</div>
